import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'sign-in',
    redirect: '/sign-in',
  },
  {
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Signin/Sign-In.vue'),
	},
  {
		path: '/user',
		name: 'user',
	
		meta:{requiresAuth:true},
		component: () => import('../views/User/user.vue'),

   
	},
	{
		path: '/user/create',
		name: 'usercreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/User/usercreate.vue'),
	},
	{
        name: 'Useredit',
        path: '/user/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/User/useredit.vue'),

	},
	{
		path: '/role',
		name: 'role',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Role/role.vue'),
	},
	{
		path: '/role/create',
		name: 'rolecreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Role/create.vue'),
	},
	{
		path: '/permission',
		name: 'permission',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Permission/permission.vue'),
	},
  {
		path: '/profile_list',
		name: 'profile_list',
		meta:{requiresAuth:true},

		component: () => import('../views/profile/list.vue'),
	},

	{
        name: 'edit-profile',
        path: '/editprofile',
		meta:{requiresAuth:true},

		component: () => import('../views/profile/edit.vue'),

	},
	{
        name: 'change_password',
        path: '/change_password',
		meta:{requiresAuth:true},

		component: () => import('../views/profile/changepassword.vue'),

	},
	{
        name: 'services_list',
        path: '/services_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/service/list.vue'),

	},
	{
		name: 'serviceedit',
        path: '/service/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/service/edit.vue'),

	},
	{
        path: '/service/create',
        name: '/service_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/service/create.vue'),

	},
	
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to,from,next)=>{
	if(to.meta.requiresAuth){
		const token = localStorage.getItem('token');
if(token){
	next()

}else{
	next(
		'/sign-in'
	);
}
	}else{
		next()
	}
})
export default router
